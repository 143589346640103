import request from '@/utils/request';
export function getCaptcha(params) {
    return request({
        url: '/public/captcha',
        method: 'get',
        params
    });
}
export function initCsrfToken() {
    return request({
        url: '/public/csrf-token',
        method: 'get'
    });
}
export function officialAccount() {
    // const info = {
    //   url: `/official-account/js-sdk?app_id=${
    //     process.env.VUE_APP_APPID
    //   }&url=${encodeURIComponent(window.location.href)}`,
    //   method: 'get'
    // }
    // console.log('officialAccount ', info.url)
    return request({
        url: `/official-account/js-sdk?app_id=${process.env.VUE_APP_APPID}&url=${encodeURIComponent(window.location.href)}`,
        method: 'get'
    });
}
export function postSafeCode(data) {
    return request({
        url: `/sms/safe-code`,
        method: 'post',
        data
    });
}
