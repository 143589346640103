import Button from "vant/es/button";
import "vant/es/button/style/index.js";
import List from "vant/es/list";
import "vant/es/list/style/index.js";
import PullRefresh from "vant/es/pull-refresh";
import "vant/es/pull-refresh/style/index.js";
import Tabbar from "vant/es/tabbar";
import "vant/es/tabbar/style/index.js";
import TabbarItem from "vant/es/tabbar-item";
import "vant/es/tabbar-item/style/index.js";
import Swipe from "vant/es/swipe";
import "vant/es/swipe/style/index.js";
import SwipeItem from "vant/es/swipe-item";
import "vant/es/swipe-item/style/index.js";
import { default as VanImage } from "vant/es/image";
import "vant/es/image/style/index.js";
import Form from "vant/es/form";
import "vant/es/form/style/index.js";
import Field from "vant/es/field";
import "vant/es/field/style/index.js";
import NavBar from "vant/es/nav-bar";
import "vant/es/nav-bar/style/index.js";
import Icon from "vant/es/icon";
import "vant/es/icon/style/index.js";
import Cell from "vant/es/cell";
import "vant/es/cell/style/index.js";
import CellGroup from "vant/es/cell-group";
import "vant/es/cell-group/style/index.js";
import Grid from "vant/es/grid";
import "vant/es/grid/style/index.js";
import GridItem from "vant/es/grid-item";
import "vant/es/grid-item/style/index.js";
import Step from "vant/es/step";
import "vant/es/step/style/index.js";
import Steps from "vant/es/steps";
import "vant/es/steps/style/index.js";
import Checkbox from "vant/es/checkbox";
import "vant/es/checkbox/style/index.js";
import CheckboxGroup from "vant/es/checkbox-group";
import "vant/es/checkbox-group/style/index.js";
import Skeleton from "vant/es/skeleton";
import "vant/es/skeleton/style/index.js";
import RadioGroup from "vant/es/radio-group";
import "vant/es/radio-group/style/index.js";
import Radio from "vant/es/radio";
import "vant/es/radio/style/index.js";
import Dialog from "vant/es/dialog";
import "vant/es/dialog/style/index.js";
import Uploader from "vant/es/uploader";
import "vant/es/uploader/style/index.js";
import Col from "vant/es/col";
import "vant/es/col/style/index.js";
import Row from "vant/es/row";
import "vant/es/row/style/index.js";
import NoticeBar from "vant/es/notice-bar";
import "vant/es/notice-bar/style/index.js";
import Divider from "vant/es/divider";
import "vant/es/divider/style/index.js";
import Search from "vant/es/search";
import "vant/es/search/style/index.js";
import Collapse from "vant/es/collapse";
import "vant/es/collapse/style/index.js";
import CollapseItem from "vant/es/collapse-item";
import "vant/es/collapse-item/style/index.js";
import Empty from "vant/es/empty";
import "vant/es/empty/style/index.js";
import Progress from "vant/es/progress";
import "vant/es/progress/style/index.js";
import Overlay from "vant/es/overlay";
import "vant/es/overlay/style/index.js";
import Popup from "vant/es/popup";
import "vant/es/popup/style/index.js";
import Popover from "vant/es/popover";
import "vant/es/popover/style/index.js";
import Sticky from "vant/es/sticky";
import "vant/es/sticky/style/index.js";
import Loading from "vant/es/loading";
import "vant/es/loading/style/index.js";
import Notify from "vant/es/notify";
import "vant/es/notify/style/index.js";
import Tag from "vant/es/tag";
import "vant/es/tag/style/index.js";
import Tab from "vant/es/tab";
import "vant/es/tab/style/index.js";
import Tabs from "vant/es/tabs";
import "vant/es/tabs/style/index.js";
import Picker from "vant/es/picker";
import "vant/es/picker/style/index.js";
import ActionSheet from "vant/es/action-sheet";
import "vant/es/action-sheet/style/index.js";
// https://vant-contrib.gitee.io/vant/v3/#/zh-CN
const plugins = [
    Button,
    List,
    Tabbar,
    TabbarItem,
    Swipe,
    SwipeItem,
    VanImage,
    PullRefresh,
    Form,
    Field,
    NavBar,
    Icon,
    Cell,
    CellGroup,
    Grid,
    GridItem,
    Step,
    Steps,
    Checkbox,
    CheckboxGroup,
    Skeleton,
    RadioGroup,
    Radio,
    Dialog,
    Uploader,
    Col,
    Row,
    NoticeBar,
    Divider,
    Search,
    Collapse,
    CollapseItem,
    Empty,
    Progress,
    Overlay,
    Popup,
    Popover,
    Sticky,
    Loading,
    Notify,
    Tag,
    Tab,
    Tabs,
    Picker,
    ActionSheet
];
export default {
    install(vm) {
        plugins.forEach(item => {
            vm.component(item.name, item);
        });
    }
};
