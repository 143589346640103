import axios from 'axios';
// eslint-disable-next-line
const config = require('../../config');
const { baseURL } = config[process.env.NODE_ENV];
// 创建axios实例
const service = axios.create({
    baseURL,
    timeout: 5000
    // withCredentials: true
});
// 请求拦截器
service.interceptors.request.use(config => {
    // if (store.getters.getToken) {
    //   config.headers.Authorization = `Bearer ${getToken()}`
    // }
    if (sessionStorage.getItem('yc_token')) {
        config.headers.Authorization = sessionStorage.getItem('yc_token');
    }
    else if (localStorage.getItem('yc_token')) {
        config.headers.Authorization = localStorage.getItem('yc_token');
    }
    // config.headers.Authorization = config.headers['x-csrf-token'] =
    //   getCsrfToken()
    return config;
}, error => {
    console.log(error);
    return Promise.reject(error);
});
// 响应拦截器
service.interceptors.response.use(response => {
    const res = response.data.meta;
    if (res.code !== 'SUCCESS') {
        return Promise.reject(res);
    }
    else {
        if (response.config?.url?.indexOf('/donor?') !== -1 ||
            response.config?.url?.indexOf('/prove') !== -1) {
            return response;
        }
        return response.data;
    }
}, error => {
    return Promise.reject(error);
});
export default service;
