import request from '@/utils/request';
export function postInfoShare(id) {
    return request({
        url: `/fund/share/${id}`,
        method: 'post'
    });
}
export function getPrepay(id) {
    return request({
        url: `/fund/info/${id}/prepay`,
        method: 'get'
    });
}
export function getInfoSelf(uuid) {
    return request({
        url: `/fund/info/${uuid}/self`,
        method: 'get'
    });
}
export function getOrder(tradeNo) {
    return request({
        url: `/fund/order/${tradeNo}`,
        method: 'get'
    });
}
export function postOrder(data) {
    return request({
        url: `/fund/order`,
        method: 'post',
        data
    });
}
export function getInfoById(uuid) {
    return request({
        url: `/fund/info/${uuid}`,
        method: 'get'
    });
}
export function getInfoUUIDById(uuid) {
    return request({
        url: `/fund/info/${uuid}/uuid`,
        method: 'get'
    });
}
export function getDonorById(uuid, last_id) {
    let lastId = '';
    if (last_id) {
        lastId = ``;
    }
    return request({
        url: `/fund/info/${uuid}/donor?last_id=${last_id}&size=20`,
        method: 'get'
    });
}
export function getWithDrawalById(uuid) {
    return request({
        url: `/fund/info/${uuid}/withdrawal`,
        method: 'get'
    });
}
export function getFeedById(uuid) {
    return request({
        url: `/fund/info/${uuid}/feed`,
        method: 'get'
    });
}
export function postFeedById(uuid, data) {
    return request({
        url: `/fund/info/${uuid}/feed`,
        method: 'post',
        data
    });
}
export function getProveById(uuid, last_id) {
    let lastId = last_id || '';
    return request({
        url: `/fund/info/${uuid}/prove?last_id=${lastId}&size=20`,
        method: 'get'
    });
}
export function postProveById(uuid, data) {
    return request({
        url: `/fund/info/${uuid}/prove`,
        method: 'post',
        data
    });
}
export function postReportById(uuid, data) {
    return request({
        url: `/fund/info/${uuid}/report`,
        method: 'post',
        data
    });
}
export function postRefund(data) {
    return request({
        url: `/fund/refund`,
        method: 'post',
        data
    });
}
export function getMine() {
    return request({
        url: `/fund/mine?size=100`,
        method: 'get'
    });
}
export function getPublishInfo() {
    return request({
        url: `/fund/publish-info`,
        method: 'get'
    });
}
export function getHospital(name) {
    return request({
        url: `/fund/hospital?name=${name}`,
        method: 'get'
    });
}
// 获取患者审核的信息
export function getReviewPatient(uuid) {
    return request({
        url: `/fund/manage/${uuid}/review/patient`,
        method: 'get'
    });
}
// 获取收款审核的信息
export function getReviewPayment(uuid) {
    return request({
        url: `/fund/manage/${uuid}/review/payment`,
        method: 'get'
    });
}
export function getPublishStatus() {
    return request({
        url: `/fund/publish-status`,
        method: 'get'
    });
}
export function getFundInfo(uuid) {
    return request({
        url: `/fund/info/${process.env.VUE_APP_APPID}`,
        method: 'get'
    });
}
export function postPublishInfo(data) {
    return request({
        url: `/fund/publish-info`,
        method: 'post',
        data
    });
}
export function postPublishAsset(data) {
    return request({
        url: `/fund/publish-asset`,
        method: 'post',
        data
    });
}
// 提交患者审核
export function postReviewPatient(uuid, data) {
    return request({
        url: `/fund/manage/${uuid}/review/patient`,
        method: 'post',
        data
    });
}
// 提交收款审核
export function postReviewPayment(uuid, data) {
    return request({
        url: `/fund/manage/${uuid}/review/payment`,
        method: 'post',
        data
    });
}
export function postBdNormalClue(data) {
    return request({
        url: `/bd/normal-clue`,
        method: 'post',
        data
    });
}
export function getBindFundById(uuid) {
    return request({
        url: `/bd/bind/fund/${uuid}`,
        method: 'get'
    });
}
export function getRegion() {
    return request({
        url: `/fund/region`,
        method: 'get'
    });
}
export function getInfoCommentById(uuid, data) {
    return request({
        url: `/fund/info/${uuid}/comment?donor_ids=${data.toString()}&entity=donor`,
        method: 'get'
    });
}
export function getDonorExport(uuid) {
    return request({
        url: `/fund/info/${uuid}/donor-export`,
        method: 'get'
    });
}
export function postDonorReply(uuid, data) {
    return request({
        url: `/fund/info/${uuid}/donor/comment`,
        method: 'post',
        data
    });
}
