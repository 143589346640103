import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './icons/index';
import './styles/index.scss';
import './utils/auth';
import './utils/permission';
import vant from '@/components/Vant';
import Lazyload from "vant/es/lazyload";
import "vant/es/lazyload/style/index.js";
// eslint-disable-next-line
const app = createApp(App);
app.directive('debounce', {
    mounted(el, binding, vnode) {
        let timer = {};
        el.addEventListener('click', () => {
            if (timer) {
                clearTimeout(timer);
            }
            timer = setTimeout(() => {
                binding.value();
            }, 500);
        });
    }
});
app.use(store).use(router).use(vant).use(Lazyload).mount('#app');
