import request from '@/utils/request';
export function getWalletByID(uuid) {
    return request({
        url: `/fund/manage/${uuid}/wallet`,
        method: 'get'
    });
}
export function postWalletByID(uuid, data) {
    return request({
        url: `/fund/manage/${uuid}/wallet/withdrawal`,
        method: 'post',
        data
    });
}
export function getModifyInfoByID(uuid) {
    return request({
        url: `/fund/manage/${uuid}/modify-info`,
        method: 'get'
    });
}
export function postModifyInfoByID(uuid, data) {
    return request({
        url: `/fund/manage/${uuid}/modify-info`,
        method: 'post',
        data
    });
}
export function postThanksByID(uuid, data) {
    return request({
        url: `/fund/manage/${uuid}/thanks`,
        method: 'post',
        data
    });
}
export function postEsignStart(data) {
    return request({
        url: `/fund/esign-start-withdrawal`,
        method: 'post',
        data
    });
}
export function postEsignStartPromise(data) {
    return request({
        url: `/fund/esign-start-promise`,
        method: 'post',
        data
    });
}
export function postEsignWithdrawalHelp(data) {
    return request({
        url: `/fund/withdrawal-sign-with-help`,
        method: 'post',
        data
    });
}
