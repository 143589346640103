import router from '@/router';
import Toast from "vant/es/toast";
import "vant/es/toast/style/index.js";
import Notify from "vant/es/notify";
import "vant/es/notify/style/index.js";
import { checkUesrId, blackList } from './auth';
import { getUser } from '@/api';
import { getWxCodeByOauth } from '@/utils/next.js';
import Bus from '@/utils/bus';
// eslint-disable-next-line
router.beforeEach(async (to, from, next) => {
    // 设置标题
    document.title = to.meta.title || '微小爱';
    // 根据token判断用户是否登录
    let hasToken = '';
    if (sessionStorage.getItem('yc_token')) {
        hasToken = sessionStorage.getItem('yc_token') || '';
    }
    else if (localStorage.getItem('yc_token')) {
        hasToken = localStorage.getItem('yc_token') || '';
    }
    if (hasToken) {
        if (to.path === '/signin') {
            // 如果已经登录了，而去的又是login页就重定向到首页
            next({ path: '/user' });
        }
        else if (checkUesrId(to.path)) {
            const userInfo = await getUser().then(res => {
                return res;
            });
            debugger;
            if (userInfo?.user_id === 0) {
                const callbackUrl = to.fullPath;
                getWxCodeByOauth(callbackUrl); // 需要绑定手机号 oauth_flag
            }
            next();
        }
        else {
            next();
        }
    }
    else {
        if (blackList.indexOf(to.path) === -1) {
            // 白名单没有token也直接放行
            next();
        }
        else {
            Notify({ type: 'primary', message: '请先登录后再操作' });
            debugger;
            getWxCodeByOauth(to.fullPath);
            // next(`/signin?${to.fullPath}`)
        }
    }
});
router.afterEach((to, from) => {
    // NProgress.done()
    if (!to.meta.controlWelcomeLoading) {
        Bus.$emit('hideWelcomeLoading');
    }
});
