module.exports = {
  mock: {
    baseURL: '/dev-api',
    mockURL: process.env.VUE_APP_BASEURL
  },
  local: {
    baseURL: '/dev-api',
    mockURL: process.env.VUE_APP_BASEURL
  },
  test: {
    baseURL: process.env.VUE_APP_BASEURL,
    mockURL: process.env.VUE_APP_BASEURL
  },
  development: {
    baseURL: '/dev-api',
    mockURL: process.env.VUE_APP_BASEURL
  },
  production: {
    baseURL: process.env.VUE_APP_BASEURL,
    mockURL: process.env.VUE_APP_BASEURL
  }
}
