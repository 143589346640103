import {
  getPublishStatus,
  getPublishInfo,
  getReviewPatient,
  getReviewPayment
} from '@/api'
import { Dialog, Notify } from 'vant'
import { useRouter } from 'vue-router'
import { getUrlParam, getUrlCallback } from '@/utils/url'
import { isWeChat } from '@/utils/wx'

export function nextStep(step = 'other') {
  // return
  const router = useRouter()
  const info = getPublishInfo().then(res => {
    if (
      res.patient_card === '' &&
      (res.state === 'none' || res.state === 'reject') &&
      parseInt(res.target_amount) > 0
    ) {
      console.log('Step2')
      router.push({
        name: 'Step2'
      })
      return res
    } else if (
      res.patient_card !== '' &&
      (res.state === 'none' || res.state === 'reject') &&
      parseInt(res.target_amount) > 0
    ) {
      console.log('Step3')
      router.push({
        name: 'Step3'
      })
      return res
    } else if (res.state === 'review') {
      console.log('Step5')
      router.push({
        name: 'Step5',
        query: {
          uuid: res.uuid
        }
      })
      return res
    } else if (step === 'init') {
      console.log('next init')
      getPublishStatus().then(res => {
        if (res.last_uuid) {
          Dialog.confirm({
            message: '您已有正在筹款的项目',
            confirmButtonText: '查看',
            cancelButtonText: '重新发起',
            confirmButtonColor: '#0fb576'
          })
            .then(() => {
              router.push({
                name: 'Info',
                query: {
                  uuid: res.last_uuid
                }
              })
            })
            .catch(() => {
              console.log('last_uuid cancel')
            })
        }
      })
      return res
    } else {
      return res
    }
  })
  return info
}

export function getWxCode(path) {
  if (sessionStorage.getItem('yc_token')) {
    return
  }
  if (!isWeChat()) {
    // const router = useRouter()
    // router.go(-1)
    window.history.back()
    Notify({ type: 'primary', message: '请在微信环境下使用' })
    return
  }
  const link = `${process.env.VUE_APP_BACKURL}/?callback=${encodeURIComponent(
    getUrlCallback('redirect', path)
  )}`
  debugger
  window.location.href = `http://open.weixin.qq.com/connect/oauth2/authorize?appid=${
    process.env.VUE_APP_APPID
  }&redirect_uri=${encodeURIComponent(
    link
  )}&response_type=code&scope=snsapi_userinfo#wechat_redirect`
}

export function getWxCodeByOauth(callbackUrl) {
  if (sessionStorage.getItem('yc_token')) {
    return
  }
  if (!isWeChat()) {
    // const router = useRouter()
    // router.go(-1)
    window.history.back()
    Notify({ type: 'primary', message: '请在微信环境下使用' })
    return
  }
  const link = `${process.env.VUE_APP_BACKURL}?callback=${encodeURIComponent(
    callbackUrl
  )}`
  debugger
  window.location.href = `http://open.weixin.qq.com/connect/oauth2/authorize?appid=${
    process.env.VUE_APP_APPID
  }&redirect_uri=${encodeURIComponent(
    link
  )}&response_type=code&scope=snsapi_userinfo#wechat_redirect`
}

export function getReviewPatientInfo(uuid) {
  return getReviewPatient(uuid).then(res => {
    return res
  })
}

export function getReviewPaymentInfo(uuid) {
  return getReviewPayment(uuid).then(res => {
    return res
  })
}
