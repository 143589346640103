import { officialAccount } from '@/api'

export function initWxConfig() {
  officialAccount().then(wx_res => {
    console.log('officialAccount+  ', wx_res)
    const currentHerf = window.location.href
    console.log('officialAccount - href -', currentHerf)

    const sdk = {
      debug: false,
      appId: wx_res.app_id, // 必填，公众号的唯一标识
      timestamp: wx_res.timestamp, // 必填，生成签名的时间戳
      nonceStr: wx_res.nonce_str, // 必填，生成签名的随机串
      signature: wx_res.signature, // 必填，签名
      jsApiList: wx_res.js_api_list // 必填，需要使用的JS接口列表
    }

    console.log('wx.config-', sdk)
    wx.config(sdk)
    wx.ready(function () {
      // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
      console.log('wx.ready')

      wx.checkJsApi({
        jsApiList: wx_res.js_api_list,
        success: function (res) {
          // 以键值对的形式返回，可用的api值true，不可用为false
          // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
          console.log('checkJsApi success' + res)
        },
        fail: function (err) {
          console.log('checkJsApi fail' + err)
        }
      })

      // wx.onMenuShareTimeline({
      //   title: res.title, // 分享标题
      //   link: currentHerf, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      //   imgUrl: lifeImg ? lifeImg : process.env.VUE_APP_LOGO, // 分享图标
      //   success: function () {
      //     console.log('info onMenuShareTimeline')
      //   },
      //   cancel: function () {
      //     console.log('info onMenuShareTimeline -- err')
      //   }
      // })
      // wx.onMenuShareAppMessage({
      //   title: res.title, // 分享标题
      //   desc: res.content,
      //   link: currentHerf, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      //   imgUrl: lifeImg ? lifeImg : process.env.VUE_APP_LOGO, // 分享图标
      //   success: function () {
      //     console.log('info onMenuShareAppMessage')
      //   },
      //   cancel: function () {
      //     console.log('info onMenuShareAppMessage -- err')
      //   }
      // })
    })
    wx.error(function (res) {
      // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      console.log('wx.error')
      console.log(res)
    })
  })
}

export function isWeChat() {
  //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
  var ua = window.navigator.userAgent.toLowerCase()
  //通过正则表达式匹配ua中是否含有MicroMessenger字符串
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    return true
  } else {
    return false
  }
}
