import Toast from "vant/es/toast";
import "vant/es/toast/style/index.js";
import Dialog from "vant/es/dialog";
import "vant/es/dialog/style/index.js";
import service from './axios';
import { getWxCodeByOauth } from '@/utils/next.js';
const request = (config) => {
    return new Promise((resolve, reject) => {
        service.request(config).then(res => {
            resolve(res.data);
        }, err => {
            switch (err?.code) {
                case 'Unauthorized':
                    // token失效
                    Dialog.alert({
                        title: '提示',
                        message: '您还未登录或登录已过期，请重新登录'
                    }).then(() => {
                        // store.dispatch('user/resetToken').then(() => {
                        //   location.reload()
                        // })
                        if (sessionStorage.getItem('yc_token')) {
                            Toast(err.msg);
                            return;
                        }
                        localStorage.clear();
                        sessionStorage.clear();
                        // Bus.$emit('Unauthorized')
                        const callbackUrl = `${window.location.pathname}${window.location.search}`;
                        debugger;
                        getWxCodeByOauth(callbackUrl);
                    });
                    break;
                default:
                    Toast(err.msg);
                    break;
            }
            reject(err);
        });
    });
};
export default request;
