import { createRouter, createWebHistory } from 'vue-router';
const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: 'Home' */ '../views/home/index.vue'),
        meta: {
            showTab: false,
            keepAlive: true
        }
    },
    {
        path: '/signin',
        name: 'Signin',
        component: () => import(/* webpackChunkName: 'Signin' */ '../views/login/signin.vue'),
        meta: {
            title: '登录'
        }
    },
    {
        path: '/signup',
        name: 'Signup',
        component: () => import(/* webpackChunkName: 'Signup' */ '../views/login/signup.vue'),
        meta: {
            title: '注册'
        }
    },
    {
        path: '/logout',
        name: 'Logout',
        component: () => import(/* webpackChunkName: 'Logout' */ '../views/login/logout.vue'),
        meta: {
            title: '登出'
        }
    },
    {
        path: '/clue',
        name: 'Clue',
        component: () => import(/* webpackChunkName: 'Clue' */ '../views/login/clue.vue'),
        meta: {
            title: '线索'
        }
    },
    {
        path: '/bindingPhone',
        name: 'BindingPhone',
        component: () => import(/* webpackChunkName: 'Clue' */ '../views/login/bindingPhone.vue'),
        meta: {
            title: '绑定手机号'
        }
    },
    {
        path: '/init',
        name: 'Initiate',
        component: () => import(/* webpackChunkName: 'Clue' */ '../views/init/index.vue'),
        meta: {
            title: '发起',
            showTab: true
        }
    },
    {
        path: '/step2',
        name: 'Step2',
        component: () => import(/* webpackChunkName: 'Clue' */ '../views/init/step2.vue')
    },
    {
        path: '/step3',
        name: 'Step3',
        component: () => import(/* webpackChunkName: 'Clue' */ '../views/init/step3.vue')
    },
    {
        path: '/step4',
        name: 'Step4',
        component: () => import(/* webpackChunkName: 'Clue' */ '../views/init/step4.vue')
    },
    {
        path: '/step5',
        name: 'Step5',
        component: () => import(/* webpackChunkName: 'Clue' */ '../views/init/step5.vue')
    },
    {
        path: '/step6',
        name: 'Step6',
        component: () => import(/* webpackChunkName: 'Clue' */ '../views/init/step6.vue')
    },
    {
        path: '/bd-step3',
        name: 'bdStep3',
        component: () => import(/* webpackChunkName: 'Clue' */ '../views/init/bdStep3.vue')
    },
    {
        path: '/review-patient',
        name: 'ReviewPatient',
        component: () => import(/* webpackChunkName: 'Clue' */ '../views/review/reviewPatient.vue')
    },
    {
        path: '/review-payment',
        name: 'ReviewPayment',
        component: () => import(/* webpackChunkName: 'Clue' */ '../views/review/reviewPayment.vue')
    },
    {
        path: '/review-hold',
        name: 'ReviewHold',
        component: () => import(/* webpackChunkName: 'Clue' */ '../views/review/reviewHold.vue')
    },
    {
        path: '/clue-hold',
        name: 'ClueHold',
        component: () => import(/* webpackChunkName: 'Clue' */ '../views/login/clueHold.vue')
    },
    {
        path: '/feed',
        name: 'Feed',
        component: () => import(/* webpackChunkName: 'Feed' */ '../views/review/feed.vue')
    },
    {
        path: '/info',
        name: 'Info',
        component: () => import(/* webpackChunkName: 'Info' */ '../views/info/index.vue'),
        meta: {
            controlWelcomeLoading: true
        }
    },
    {
        path: '/payment',
        name: 'Payment',
        component: () => import(/* webpackChunkName: 'Payment' */ '../views/info/payment.vue')
    },
    {
        path: '/payment2',
        name: 'Payment2',
        component: () => import(/* webpackChunkName: 'Payment' */ '../views/info/payment2.vue')
    },
    {
        path: '/payment-success',
        name: 'PaymentSuccess',
        component: () => import(
        /* webpackChunkName: 'Payment' */ '../views/info/paymentSuccess.vue')
    },
    {
        path: '/prove',
        name: 'Prove',
        component: () => import(/* webpackChunkName: 'Payment' */ '../views/info/prove.vue')
    },
    {
        path: '/refund',
        name: 'Refund',
        component: () => import(/* webpackChunkName: 'Payment' */ '../views/info/refund.vue')
    },
    {
        path: '/report',
        name: 'Report',
        component: () => import(/* webpackChunkName: 'Payment' */ '../views/info/report.vue')
    },
    {
        path: '/all-thanks',
        name: 'AllThanks',
        component: () => import(/* webpackChunkName: 'Payment' */ '../views/info/allThanks.vue')
    },
    {
        path: '/search-hospital',
        name: 'SearchHospital',
        component: () => import(
        /* webpackChunkName: 'Payment' */ '../views/review/searchHospital.vue')
    },
    {
        path: '/power/svg-icon',
        name: 'SvgIcon',
        component: () => import(
        /* webpackChunkName: 'Payment' */ '../views/init/demo/svgicon.vue'),
        meta: {
            title: '图标组件'
        }
    },
    {
        path: '/user',
        name: 'User',
        component: () => import(/* webpackChunkName: 'User' */ '../views/user/index.vue'),
        meta: {
            title: '个人中心',
            showTab: true
        }
    },
    {
        path: '/fund-list',
        name: 'FundList',
        component: () => import(/* webpackChunkName: 'User' */ '../views/user/fundList.vue')
    },
    {
        path: '/bd-agent',
        name: 'BdAgent',
        component: () => import(/* webpackChunkName: 'User' */ '../views/user/bdAgent.vue')
    },
    {
        path: '/protocol',
        name: 'Protocol',
        component: () => import(/* webpackChunkName: 'User' */ '../views/user/protocol.vue')
    },
    {
        path: '/apply-withdrawal',
        name: 'ApplyWithdrawal',
        component: () => import(/* webpackChunkName: 'User' */ '../views/user/applyWithdrawal.vue')
    },
    {
        path: '/withdrawal-list',
        name: 'WithdrawalList',
        component: () => import(/* webpackChunkName: 'User' */ '../views/user/withdrawalList.vue')
    },
    {
        path: '/sign-form',
        name: 'SignForm',
        component: () => import(/* webpackChunkName: 'User' */ '../views/user/signPage.vue'),
        meta: {
            title: '签署诚信承诺书'
        }
    },
    {
        path: '/withdrawal-help-sign-form',
        name: 'WithdrawalHelpSignForm',
        component: () => import(
        /* webpackChunkName: 'User' */ '../views/user/withdrawalHelpSignPage.vue'),
        meta: {
            title: '您的亲属邀请你代签署'
        }
    },
    {
        path: '/user-st',
        name: 'st',
        component: () => import(/* webpackChunkName: 'User' */ '../views/user/st.vue'),
        meta: {
            title: 'setToken'
        }
    },
    {
        path: '/activity/mobileWatch',
        name: 'MobileWatch',
        component: () => import(
        /* webpackChunkName: 'Activity' */ '../views/mobileWatch/index.vue'),
        meta: {
            title: '免费领取智能手表',
            showTab: true
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: '404',
        redirect: '/'
    }
];
const router = createRouter({
    history: createWebHistory(),
    routes
});
export default router;
