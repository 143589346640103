import Cookies from 'js-cookie';
// 用户cookie
const key = 'H5Token';
export function getToken() {
    return Cookies.get(key);
}
export function setToken(token) {
    return Cookies.set(key, token, { expires: 15 });
}
export function removeToken() {
    return Cookies.remove(key);
}
// 安全cookie
export function getCsrfToken() {
    return Cookies.get('csrfToken');
}
// 需要token才能访问
export const blackList = [
    '/init',
    '/step2',
    '/step3',
    '/step4',
    '/step5',
    '/feed',
    '/review-payment',
    '/review-patient',
    '/prove',
    '/report',
    '/refund',
    '/user',
    '/all-thanks',
    '/apply-withdrawal',
    '/payment',
    '/activity/mobileWatch',
    '/sign-form',
    '/all-thanks',
];
// 需要user_id > 0 访问
export const hasUserIdList = [
    '/init',
    '/step2',
    '/step3',
    '/step4',
    '/step5',
    '/feed',
    '/review-payment',
    '/review-patient',
    '/prove',
    '/user',
    '/all-thanks',
    '/apply-withdrawal',
    '/sign-form',
    '/all-thanks',
];
export function checkUesrId(path) {
    console.log(hasUserIdList);
    for (let i = 0; i < hasUserIdList.length; i++) {
        const url = hasUserIdList[i];
        if (path.indexOf(url) !== -1) {
            return true;
        }
    }
    return false;
}
