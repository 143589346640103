export function getUrlParam(name) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (pair[0] == name) {
            return decodeURIComponent(pair[1]);
        }
    }
    return false;
}
export function getUrlCallback(name, path) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (pair[0] == name) {
            let url = '';
            if (pair[2]) {
                url = pair[1] + '=' + pair[2];
            }
            else {
                url = pair[1];
            }
            return url;
        }
    }
    return path;
}
export function changeURLArg(url, arg, arg_val) {
    var pattern = arg + '=([^&]*)';
    var replaceText = arg + '=' + arg_val;
    if (url.match(pattern)) {
        var tmp = '/(' + arg + '=)([^&]*)/gi';
        tmp = url.replace(eval(tmp), replaceText);
        return tmp;
    }
    else {
        if (url.match('[?]')) {
            return url + '&' + replaceText;
        }
        else {
            return url + '?' + replaceText;
        }
    }
    return url + '\n' + arg + '\n' + arg_val;
}
