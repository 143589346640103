import request from '@/utils/request';
export function getUser() {
    return request({
        url: `/passport/user`,
        method: 'get'
    });
}
export function getOssTicket() {
    return request({
        url: `/passport/oss-ticket`,
        method: 'get'
    });
}
export function postSignIn(data) {
    return request({
        url: `/passport/sign-in`,
        method: 'post',
        data
    });
}
export function postOauth(data) {
    return request({
        url: `/passport/oauth`,
        method: 'post',
        data
    });
}
